<template >
  <b-overlay 
    :show="isLoading"
    rounded = "lg"
    opacity="0.6"
    spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="container-fluid mt-1 pt-1">
      <div class="row">
        <h4 v-if = "formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" > Detail Discount </h4>
        <h4 v-else > Update Discount</h4>

        </div>
        <div>
        <b-card class="bg-warning shadow-sm">
          <div class="row">
            <div class="col-md-1">
              <i class="fa fa-info-circle fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-md-10">
              <div class="text-light">
                <ul>
                  <li> If input Offer Type Specified Offer make sure offers available with status Ready For Sale. </li>
                  <li> Bin Codes only for Payment with Credit Card. </li>
                  <li> Trial Promotion only for Specified Offer, can't assign for single offer and not allowed for APPLE ITUNES Payment or IOS Platforms </li>
                </ul>  
              </div>
            </div>
          </div>
        </b-card>
        </div>
        <b-form @submit="onSubmit" @reset="reset" v-if="show">
          <div class="card">
            <div class="card-header bg-primary">
              <h5 class="card-title mb-0 font-weight-bold">General</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-discountName"
                    label="Discount Name"
                    label-for="discount-Name"
                  >
                    <b-form-input
                      id="discount-Name"
                      v-model="form.name"
                      type="text"
                      placeholder="Enter Discount Name"
                      required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true"                
                      ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Campaign">
                    <multiselect
                      v-model="form.campaign"
                      label="name"
                      track-by="name"
                      placeholder="Type Campaign"
                      open-direction="bottom"
                      :options="campaignSearchOptions"
                      :searchable="true"
                      :loading="isCampaignSearch"
                      :close-on-select="true"
                      :options-limit="10"
                      :multiple="false"
                      @search-change="campaignFind" 
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true"  
                    >
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <v-date-picker v-model="form.valid_from" mode="dateTime"  :timezone="timezone">
                    <template v-slot="{ inputValue, inputEvents }">
                      <b-form-group id="input-validFrom" label="Valid From"  label-for="ValidFrom" >
                        <input
                          id="ValidFrom"
                          autocomplete="off"
                          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                        />
                    </b-form-group>
                    </template>
                  </v-date-picker>
                </div>
                <div class="col-md-3">
                    <v-date-picker v-model="form.valid_to" mode="dateTime" :timezone="timezone">
                      <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group id="input-validTo" label="Valid To"  label-for="ValidTo" >
                        <input
                          id="validTo"
                          autocomplete="off"
                          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents" 
                        />
                        </b-form-group>
                      </template>
                    </v-date-picker>
                    <div class="flex mt-2">
                      <label class="text-gray-600 font-medium">
                        <input class="mr-1" 
                        type="radio" 
                        value="" v-model="timezone"
                        :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true">
                        Local
                      </label>
                      <label class="text-gray-600 font-medium ml-3">
                        <input class="mr-1" 
                        type="radio" 
                        value="utc" 
                        v-model="timezone" 
                        :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" >
                        UTC
                      </label>
                    </div>
                </div>
                <div class="col-md-6">
                  <b-form-group id="input-salesRecognition" label="Sales Recognition"  label-for="salesRecognition" >
                      <b-form-select  id="salesRecognition"  v-model="form.salesRecognition" :options="salesRecognitions" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="input-partnerParticipation" label="Partner Participation"  label-for="partnerParticipation" >
                      <b-form-select  id="partnerParticipation"  v-model="form.partnerParticipation" :options="partnerParticipations" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="input-vendorParticipation" label="Vendor Participation"  label-for="vendorParticipation" >
                      <b-form-select  id="vendorParticipation"  v-model="form.vendorParticipation" :options="vendorParticipations" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header bg-primary">
              <h5 class="card-title mb-0 font-weight-bold">Detail</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <b-form-group id="input-discountType" label="Discount Type"  label-for="discountType" >
                      <b-form-select  id="discountType"  v-model="form.discountType" :options="discountTypes" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <label>Platforms</label>
                  <multiselect
                    v-model="form.platforms"
                    label="name"
                    track-by="name"
                    placeholder="Select Platforms"
                    open-direction="bottom"
                    :options="platformsOptions"
                    :multiple="true"
                    :searchable="true"
                    :close-on-select="false"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  >
                  </multiselect>
                </div>
                <div class="col-md-4">
                  <label>Payment Gateway</label>
                  <multiselect
                    v-model="form.paymentGateways"
                    label="name"
                    track-by="name"
                    placeholder="Select Payment Gateway"
                    open-direction="bottom"
                    :options="paymentGatewaysOptions"
                    :multiple="true"
                    :searchable="true"
                    :close-on-select="false"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  >
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b-form-group id="input-discountRule" label="Discount Rule"  label-for="discountRule" >
                      <b-form-select  id="discountRule"  v-model="form.discountRule" :options="discountRules" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <label>BIN Codes</label>
                  <multiselect
                    v-model="form.binCodes"
                    label="name"
                    track-by="name"
                    placeholder="Select Bin Codes"
                    open-direction="bottom"
                    :options="binCodesOptions"
                    :multiple="true"
                    :searchable="true"
                    :close-on-select="false"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  >
                  </multiselect>
                  <small id="binCodesHelp" class="form-text text-muted" >* Choose BIN, for Credit Card BIN Promotions.</small>
                </div>
                <!-- <div class="col-md-4">
                    <b-form-group id="input-trialPromotions" label="Trial Promotions"  label-for="trialPromotions" >
                      <b-form-select  id="trialPromotions"  v-model="form.trialPromotions" :options="trialPromotions"
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                      <small id="trialsHelp" class="form-text text-muted">Select X Days, for set trial promotions offer.</small>
                    </b-form-group>
                </div> -->
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b-form-group
                    id="input-IDRPrice"
                    label="Discount IDR"
                    label-for="discountIDR"
                  >
                  <b-form-input
                    id="IDRPrice"
                    v-model.number="form.discount_idr"
                    type="number"
                    step="any"
                    placeholder="example 1200000"
                    :min="0"
                    @update="showPreviewFormat($event, 'discIdr', 'idr')"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  ></b-form-input>
                  <small id="formatDiscIdr" class="form-text text-muted"> {{ this.previewFormat.discIdr }}</small>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group
                  id="input-USDPrice"
                  label="Discount USD Price"
                  label-for="USDPrice"
                >
                  <b-form-input
                    id="USDPrice"
                    v-model.number="form.discount_usd"
                    type="number"
                    step="any"
                    placeholder="example 1.2"
                    :min="0"
                    @update="showPreviewFormat($event, 'discUsd', 'usd')"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  ></b-form-input>
                  <small id="formatDiscUsd" class="form-text text-muted"> {{ this.previewFormat.discUsd }}</small>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group
                  id="input-point"
                  label="Discount Point"
                  label-for="point"
                >
                <b-form-input
                  id="Point"
                  v-model.number="form.point"
                  type="number"
                  step="any"
                  placeholder="example 111"
                  :min="0"
                  required
                  :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-form-group
                  id="input-minUSDPrice"
                  label="Min USD Order Price"
                  label-for="minUSDPrice"
                >
                  <b-form-input
                    id="minUSDPrice"
                    v-model.number="form.minUSDPrice"
                    type="number"
                    step="any"
                    placeholder="example 1.2"
                    :min="0"
                    @update="showPreviewFormat($event, 'minUsd', 'usd')"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  ></b-form-input>
                  <small id="formatMinUsd" class="form-text text-muted"> {{ this.previewFormat.minUsd }}</small>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group
                  id="input-maxUSDPrice"
                  label="Max USD Order Price"
                  label-for="maxUSDPrice"
                >
                  <b-form-input
                    id="maxUSDPrice"
                    v-model.number="form.maxUSDPrice"
                    type="number"
                    step="any"
                    placeholder="example 5.5"
                    :min="0"
                    @update="showPreviewFormat($event, 'maxUsd', 'usd')"
                    required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                  ></b-form-input>
                  <small id="formatMaxUsd" class="form-text text-muted"> {{ this.previewFormat.maxUsd }}</small>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group
                  id="input-minIDRPrice"
                  label="Min IDR Order Price"
                  label-for="minIDRPrice"
                >
                  <b-form-input
                      id="minIDRPrice"
                      v-model.number="form.minIDRPrice"
                      type="number"
                      step="any"
                      placeholder="1.200.000"
                      :min="0"
                      @update="showPreviewFormat($event, 'minIdr', 'idr')"
                      required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                    ></b-form-input>
                    <small id="formatMinIdr" class="form-text text-muted"> {{ this.previewFormat.minIdr }}</small>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group
                    id="input-maxIDRPrice"
                    label="Max IDR Order Price"
                    label-for="maxIDRPrice"
                  >
                    <b-form-input
                      id="maxIDRPrice"
                      v-model.number="form.maxIDRPrice"
                      type="number"
                      step="any"
                      placeholder="1.200.000"
                      :min="0"
                      @update="showPreviewFormat($event, 'maxIdr', 'idr')"
                      required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                    ></b-form-input>
                    <small id="formatMaxIdr" class="form-text text-muted"> {{ this.previewFormat.maxIdr }}</small>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <b-form-group id="input-offerType" label="Offer Type"  label-for="offerType" >
                      <b-form-select  id="offerType" v-model="form.offerType" :options="offerTypes" required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group  v-if="form.offerType == 1" id="input-selectedOffer" label="Selected Offer"  label-for="selectedOffer" >
                    <b-form-textarea
                      id="textarea"
                      v-model="form.specifiedOffer"
                      placeholder="1,1,1,1,1"
                      rows="8"
                      max-rows="9"
                      @change="form.publisher =[], form.generalOffer=null"
                      required
                      :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" >
                    </b-form-textarea>
                  </b-form-group>
                  <b-form-group v-if="form.offerType == 2" id="input-generalOffer" label="General Offer"  label-for="generalOffer" >
                    <b-form-select  id="generalOffer"  v-model="form.generalOffer" :options="generalOffers"   @change="form.specifiedOffer = ''" required
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b-form-group label="Publisher" v-if="form.generalOffer == 10 || form.generalOffer == 11 || form.generalOffer == 12 || form.generalOffer == 13">
                      <multiselect
                        v-model="form.publisher"
                        label="name"
                        track-by="name"
                        placeholder="Type Publisher"
                        open-direction="bottom"
                        :options="publisherSearchOptions"
                        :searchable="true"
                        :loading="isPublisherSearch"
                        :close-on-select="true"
                        :options-limit="10"
                        :multiple="false"
                        @search-change="publisherFind"  
                        :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true" 
                      >
                      </multiselect>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.is_active"
                    name="checkbox-1"
                    value= 'true'
                    unchecked-value="false"
                    :disabled="formatingActive(form.min, form.valid_from_detail, form.valid_to_detail) == true"
                  >
                  </b-form-checkbox>
                  <div>Active: <strong>{{ form.is_active }}</strong></div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pb-3">
              <b-button  class="mr-3 text-white" type="submit" variant="primary"> Update Discount </b-button>
              <b-button variant="outline-primary" @click="$router.push('/discounts')">Cancel</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import constant from "../../store/constant";
  import moment from "moment";
  import moment_timezone from 'moment-timezone'
  const PARTNER_PARTICIPATION = Object.entries(constant.PARTNER_PARTICIPATION).map((item) => {
    return {
      text: item[0],
      value: item[1],
    }
  });

const DISCOUNT_TYPES = Object.entries(constant.DISCOUNT_TYPES).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
  
  const DISCOUNT_PREDEFINED_GROUP = Object.entries(constant.DISCOUNT_PREDEFINED_GROUP).map((item)=>{
    return {
      text: item[0],
      value: item[1],
    }
  });

const OFFER_DISCOUNT_RULE =  Object.entries(constant.OFFER_DISCOUNT_RULE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

// const TRIALS = Object.entries(constant.TRIALS).map((item) => {
//   return {
//     text: item[0],
//     value: item[1],
//   }
// });
export default {
  name: "updateDiscountOffer",
  data() {
    return {
      form: {
        campaign: "",
        name: "",
        salesRecognition: null,
        partnerParticipation: null,
        vendorParticipation: null,
        discountType: null,
        platforms: [],
        paymentGateways: [],
        discountRule: null,
        // trialPromotions: '0 days',
        binCodes: [],
        discount_usd: 0,
        discount_idr: 0,
        minUSDPrice: 0,
        point: 0,
        maxUSDPrice: 0,
        minIDRPrice: 0,
        maxIDRPrice: 0,
        offerType: null,
        generalOffer: null,
        specifiedOffer: "",
        valid_from: "",
        valid_to: "",
        valid_from_detail: "",
        valid_to_detail: "",
        publisher: [],
        is_active: 'false',
        min : moment().format('YYYY-MM-DD hh:mm A'),
      },
        timezone: '',
       
      salesRecognitions: [
        { value: null, text: 'Select Sales Recognitions' },
        { value: 1, text: 'Recognize as Normal Sales' },
        { value: 2, text: 'Recognize as other incomes' },
      ],
      partnerParticipations: [
        { value: null, text: 'Select Partner Participations' },
        ...PARTNER_PARTICIPATION,
      ],
      vendorParticipations: [
        { value: null, text: 'Select Vendor Participations' },
        ...PARTNER_PARTICIPATION,
      ],
      discountTypes: [
        { value: null, text: 'Select Discount Type' },
        ...DISCOUNT_TYPES
      ],
      discountRules: [
        { value: null, text: 'Select Discount Rule' },
        ...OFFER_DISCOUNT_RULE,
      ],
      // trialPromotions: [
      //   { value: '0 days', text: 'Select Trial Day' },
      //   ...TRIALS,
      // ],
      offerTypes: [
        { value: null, text: '--------' },
        { value: 1, text: 'Specified Offer' },
        { value: 2, text: 'General Offer' },
      ],
      generalOffers: [
        { value: null, text: 'Select Predifined Group' },
        ...DISCOUNT_PREDEFINED_GROUP,
      ],
      previewFormat: {
        discIdr: "",
        discUsd: "",
        minUsd: "",
        maxUsd: "",
        minIdr: "",
        maxIdr: "",
      },
      show: true,
      campaignSearchOptions: [],
      isCampaignSearch: false,
      publisherSearchOptions: [],
      isPublisherSearch: false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.discounts.isError,
      isLoading: (state) => state.discounts.isLoading,
      errorMessage: (state) => state.discounts.errorMessage,
      successMessage: (state) => state.discounts.successMessage,
      item: (state) => state.discounts.item,
    
    }),
  },
  watch: {
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push({
        path: `/discounts`,
      });
    },
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.setEditDiscount();
    },
  },
  mounted() {
  },
  created: function() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.platformsOptions = Object.entries(constant.PLATFORM_PROMO).map((item) => {
      return {
        name: item[0],
        value: item[1],
      }
    });
    this.paymentGatewaysOptions = Object.entries(constant.PAYMENT_GATEWAY).map((item) => {
      return {
        name: item[0],
        value: item[1],
      }
    });
    this.binCodesOptions = constant.ACTIVE_BIN.map((item) => {
      return {
        name: item.name,
        value: item.value,
      }
    });
    this.fetchDiscountsDetailByid(id);
  },
  methods: {
    ...mapActions("campaign", ["searchCampaign"]),
    ...mapActions("discounts", [
      "discountUpdate",
      "fetchDiscountsByid",
      "fetchDiscountsDetailByid",
      "searchVendors",
      "fetchDiscountsPlatformsid",
      "fetchCampaignById",
      "fetchDiscountsPaymentsid",
      "fetchDiscountsOffersid",
      "fetchDiscountsVendorid",
      "fetchVendorsId",
    ]),
    onSubmit(event) {
      event.preventDefault();
      const data = this.form;
      const payload = {
        id: this.$route.params.id,
        name: data.name,
        campaign_id: data.campaign.length ? data.campaign.map(item => item.id):data.campaign.id,
        sales_recognition: data.salesRecognition,
        partner_participation: data.partnerParticipation,
        vendor_participation: data.vendorParticipation,
        platforms: data.platforms.map(item => item.value),
        discount_type: data.discountType,
        payment_gateways: data.paymentGateways.map(item => item.value),
        discount_rule: data.discountRule,
        // trial_time: data.trialPromotions,
        bin_codes: data.binCodes.map(item => item.value),
        discount_usd: data.discount_usd ?? 0,
        discount_idr: data.discount_idr ?? 0,
        min_usd_order_price: data.minUSDPrice ?? 0,
        max_usd_order_price: data.maxUSDPrice ?? 0,
        min_idr_order_price: data.minIDRPrice ?? 0,
        max_idr_order_price: data.maxIDRPrice ?? 0,
        offer_type: data.offerType,
        predefined_group: data.generalOffer,
        offers: data.specifiedOffer,
        valid_from: data.valid_from,
        valid_to: data.valid_to,
        discount_point: data.point ?? 0,
        vendor_id: data.publisher.length ? data.publisher.map(item => item.id) : data.publisher.id,
        is_active: data.is_active == 'true' ? true : false,
      }
      this.discountUpdate(payload);
    },
    formatDate(tgl) {
      return moment(tgl).format('YYYY-MM-DD HH:mm:ss');
    },
    formatingActive (today, fromDate, validToDate){
     if (today >= fromDate && today <= validToDate){
      return true
     }
     return today >= validToDate;
    },
    campaignFind(query) {
      if (!query) return;
      this.isCampaignSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCampaign(payload).then((response) => {
          this.campaignSearchOptions = response.data.data.rows;
          this.isCampaignSearch = false;
        }).catch(() => {
          this.isCampaignSearch = false;
        });
      }, 600);
    },
    publisherFind(query) {
      if (!query) return;

      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload).then((response) => {
          this.publisherSearchOptions = response.data.data.rows;
          this.isPublisherSearch = false;
        }).catch(() => {
          this.isPublisherSearch = false;
        });
      }, 600);
    },

    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    setEditDiscount() 
    {
      const data = this.item;
      this.form.valid_from = moment(data.valid_from).format('YYYY-MM-DD hh:mm A');
      this.form.valid_to = moment(data.valid_to).format('YYYY-MM-DD hh:mm A');
      this.form.name = data.name;
      this.form.salesRecognition = data.sales_recognition;
      this.form.partnerParticipation = data.partner_participation;
      this.form.vendorParticipation = data.vendor_participation;
      this.form.discountType = data.discount_type;
      this.fetchCampaignById(data.campaign_id).then(response => {
        this.form.campaign = response.data?.data?.rows[0];
        this.vendorSearchOptions = response.data?.data?.rows;
      });
      this.fetchDiscountsPlatformsid(data.id).then(response => {
        const platforms = [];
        const listPlatforms = response.data.data;
        listPlatforms.map(item => {
          platforms.push(item.platform_id);
        });
        this.form.platforms = this.platformsOptions.filter(
          item => [...platforms].includes(item.value)
        );
      });
      this.fetchDiscountsPaymentsid(data.id).then(response => {
        const payments = [];
        const listPayments = response.data.data;
        listPayments.map(item => {
          payments.push(item.paymentgateway_id)
        })
        this.form.paymentGateways = this.paymentGatewaysOptions.filter(
          item => [...payments].includes(item.value)
        );
      });
      this.form.discountRule = data.discount_rule;
      // this.form.trialPromotions = data.trial_time.days ?? '0 days';
      this.form.binCodes = this.binCodesOptions.filter(item => [...data.bin_codes].includes(item.value));
      this.form.discount_usd = data.discount_usd;
      this.form.discount_idr = data.discount_idr;
      this.form.point = data.discount_point;
      this.form.minUSDPrice = data.min_usd_order_price;
      this.form.maxUSDPrice = data.max_usd_order_price;
      this.form.minIDRPrice = data.min_idr_order_price;
      this.form.maxIDRPrice = data.max_idr_order_price;
      this.form.offerType = (data.predefined_group != null) ? 2 : 1;
      this.form.generalOffer = data.predefined_group ?? null;
      this.fetchDiscountsOffersid(data.id).then(response => {
        const offers = response.data.data;
        const tempOffers = [];
        offers.map(item => {
          tempOffers.push(item.offer_id);
        });
        this.form.specifiedOffer = (data.predefined_group == null) ? tempOffers.sort().toString() : '';
      });
      this.form.valid_from_detail = moment_timezone.tz(data.valid_from, "Asia/Jakarta").format( 'YYYY-MM-DD HH:mm:ss');
      this.form.valid_to_detail = moment_timezone.tz(data.valid_to, "Asia/Jakarta").format( 'YYYY-MM-DD HH:mm:ss');
      // this.form.time_valid_from = moment_timezone.tz(data.valid_from, "Asia/Jakarta").format( 'HH:mm:ss');
      // this.form.time_valid_to = moment_timezone.tz(data.valid_to, "Asia/Jakarta").format( 'HH:mm:ss');
      this.fetchDiscountsVendorid(data.id).then(response =>{
        if (response.data.data != null) {
          this.fetchVendorsId(response.data.data.vendor_id).then(result => {
            this.form.publisher =  result.data?.data?.rows[0];
            this.publisherSearchOptions =result.data?.data?.rows;
          });
        }
      });
      this.form.is_active = data.is_active ? 'true' : 'false';
      this.previewFormat.discUsd = this.numberFormatUsd(data.discount_usd);
      this.previewFormat.discIdr = this.numberFormatIdr(data.discount_idr);
      this.previewFormat.minUsd = this.numberFormatUsd(data.min_usd_order_price);
      this.previewFormat.maxUsd = this.numberFormatUsd(data.max_usd_order_price);
      this.previewFormat.minIdr = this.numberFormatIdr(data.min_idr_order_price);
      this.previewFormat.maxIdr = this.numberFormatIdr(data.max_idr_order_price);
      
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    numberFormatIdr(money) {
      return new Intl.NumberFormat(
        'id-ID',
        {
          style: 'currency',
          currency: 'IDR'
        }
      ).format(money);
    },
    numberFormatUsd(money) {
      return new Intl.NumberFormat(
        'en-US',
        {
          style: 'currency',
          currency: 'USD'
        }
      ).format(money);
    },
    showPreviewFormat(value, field, currency) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const money = (value == null) ? 0 : value;
        switch (currency) {
          case 'idr':
            this.previewFormat[field] = this.numberFormatIdr(money);
            break;
          case 'usd':
            this.previewFormat[field] = this.numberFormatUsd(money);
            break;
          default:
            break;
        }
			}, 1000);
    },
  }
};
</script>
