var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "container-fluid mt-1 pt-1"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true ? _c('h4', [_vm._v(" Detail Discount ")]) : _c('h4', [_vm._v(" Update Discount")])]), _c('div', [_c('b-card', {
    staticClass: "bg-warning shadow-sm"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-1"
  }, [_c('i', {
    staticClass: "fa fa-info-circle fa-4x",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "text-light"
  }, [_c('ul', [_c('li', [_vm._v(" If input Offer Type Specified Offer make sure offers available with status Ready For Sale. ")]), _c('li', [_vm._v(" Bin Codes only for Payment with Credit Card. ")]), _c('li', [_vm._v(" Trial Promotion only for Specified Offer, can't assign for single offer and not allowed for APPLE ITUNES Payment or IOS Platforms ")])])])])])])], 1), _vm.show ? _c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.reset
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("General")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-discountName",
      "label": "Discount Name",
      "label-for": "discount-Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "discount-Name",
      "type": "text",
      "placeholder": "Enter Discount Name",
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Campaign"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Campaign",
      "open-direction": "bottom",
      "options": _vm.campaignSearchOptions,
      "searchable": true,
      "loading": _vm.isCampaignSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "search-change": _vm.campaignFind
    },
    model: {
      value: _vm.form.campaign,
      callback: function ($$v) {
        _vm.$set(_vm.form, "campaign", $$v);
      },
      expression: "form.campaign"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "dateTime",
      "timezone": _vm.timezone
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents;
        return [_c('b-form-group', {
          attrs: {
            "id": "input-validFrom",
            "label": "Valid From",
            "label-for": "ValidFrom"
          }
        }, [_c('input', _vm._g({
          staticClass: "px-2 py-1 border rounded focus:outline-none focus:border-blue-300",
          attrs: {
            "id": "ValidFrom",
            "autocomplete": "off",
            "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
          },
          domProps: {
            "value": inputValue
          }
        }, inputEvents))])];
      }
    }], null, false, 3285298443),
    model: {
      value: _vm.form.valid_from,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_from", $$v);
      },
      expression: "form.valid_from"
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "dateTime",
      "timezone": _vm.timezone
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var inputValue = _ref2.inputValue,
          inputEvents = _ref2.inputEvents;
        return [_c('b-form-group', {
          attrs: {
            "id": "input-validTo",
            "label": "Valid To",
            "label-for": "ValidTo"
          }
        }, [_c('input', _vm._g({
          staticClass: "px-2 py-1 border rounded focus:outline-none focus:border-blue-300",
          attrs: {
            "id": "validTo",
            "autocomplete": "off"
          },
          domProps: {
            "value": inputValue
          }
        }, inputEvents))])];
      }
    }], null, false, 891405194),
    model: {
      value: _vm.form.valid_to,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_to", $$v);
      },
      expression: "form.valid_to"
    }
  }), _c('div', {
    staticClass: "flex mt-2"
  }, [_c('label', {
    staticClass: "text-gray-600 font-medium"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.timezone,
      expression: "timezone"
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "radio",
      "value": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    domProps: {
      "checked": _vm._q(_vm.timezone, "")
    },
    on: {
      "change": function ($event) {
        _vm.timezone = "";
      }
    }
  }), _vm._v(" Local ")]), _c('label', {
    staticClass: "text-gray-600 font-medium ml-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.timezone,
      expression: "timezone"
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "radio",
      "value": "utc",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    domProps: {
      "checked": _vm._q(_vm.timezone, "utc")
    },
    on: {
      "change": function ($event) {
        _vm.timezone = "utc";
      }
    }
  }), _vm._v(" UTC ")])])], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-salesRecognition",
      "label": "Sales Recognition",
      "label-for": "salesRecognition"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "salesRecognition",
      "options": _vm.salesRecognitions,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.salesRecognition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "salesRecognition", $$v);
      },
      expression: "form.salesRecognition"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-partnerParticipation",
      "label": "Partner Participation",
      "label-for": "partnerParticipation"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "partnerParticipation",
      "options": _vm.partnerParticipations,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.partnerParticipation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "partnerParticipation", $$v);
      },
      expression: "form.partnerParticipation"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-vendorParticipation",
      "label": "Vendor Participation",
      "label-for": "vendorParticipation"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vendorParticipation",
      "options": _vm.vendorParticipations,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.vendorParticipation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorParticipation", $$v);
      },
      expression: "form.vendorParticipation"
    }
  })], 1)], 1)])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Detail")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-discountType",
      "label": "Discount Type",
      "label-for": "discountType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "discountType",
      "options": _vm.discountTypes,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountType", $$v);
      },
      expression: "form.discountType"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('label', [_vm._v("Platforms")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Select Platforms",
      "open-direction": "bottom",
      "options": _vm.platformsOptions,
      "multiple": true,
      "searchable": true,
      "close-on-select": false,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.platforms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "platforms", $$v);
      },
      expression: "form.platforms"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('label', [_vm._v("Payment Gateway")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Select Payment Gateway",
      "open-direction": "bottom",
      "options": _vm.paymentGatewaysOptions,
      "multiple": true,
      "searchable": true,
      "close-on-select": false,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.paymentGateways,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentGateways", $$v);
      },
      expression: "form.paymentGateways"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-discountRule",
      "label": "Discount Rule",
      "label-for": "discountRule"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "discountRule",
      "options": _vm.discountRules,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.discountRule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountRule", $$v);
      },
      expression: "form.discountRule"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('label', [_vm._v("BIN Codes")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Select Bin Codes",
      "open-direction": "bottom",
      "options": _vm.binCodesOptions,
      "multiple": true,
      "searchable": true,
      "close-on-select": false,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.binCodes,
      callback: function ($$v) {
        _vm.$set(_vm.form, "binCodes", $$v);
      },
      expression: "form.binCodes"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "binCodesHelp"
    }
  }, [_vm._v("* Choose BIN, for Credit Card BIN Promotions.")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-IDRPrice",
      "label": "Discount IDR",
      "label-for": "discountIDR"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "IDRPrice",
      "type": "number",
      "step": "any",
      "placeholder": "example 1200000",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'discIdr', 'idr');
      }
    },
    model: {
      value: _vm.form.discount_idr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discount_idr", _vm._n($$v));
      },
      expression: "form.discount_idr"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatDiscIdr"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.discIdr))])], 1)], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-USDPrice",
      "label": "Discount USD Price",
      "label-for": "USDPrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "USDPrice",
      "type": "number",
      "step": "any",
      "placeholder": "example 1.2",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'discUsd', 'usd');
      }
    },
    model: {
      value: _vm.form.discount_usd,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discount_usd", _vm._n($$v));
      },
      expression: "form.discount_usd"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatDiscUsd"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.discUsd))])], 1)], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-point",
      "label": "Discount Point",
      "label-for": "point"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Point",
      "type": "number",
      "step": "any",
      "placeholder": "example 111",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.point,
      callback: function ($$v) {
        _vm.$set(_vm.form, "point", _vm._n($$v));
      },
      expression: "form.point"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-minUSDPrice",
      "label": "Min USD Order Price",
      "label-for": "minUSDPrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "minUSDPrice",
      "type": "number",
      "step": "any",
      "placeholder": "example 1.2",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'minUsd', 'usd');
      }
    },
    model: {
      value: _vm.form.minUSDPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minUSDPrice", _vm._n($$v));
      },
      expression: "form.minUSDPrice"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMinUsd"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.minUsd))])], 1)], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-maxUSDPrice",
      "label": "Max USD Order Price",
      "label-for": "maxUSDPrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "maxUSDPrice",
      "type": "number",
      "step": "any",
      "placeholder": "example 5.5",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'maxUsd', 'usd');
      }
    },
    model: {
      value: _vm.form.maxUSDPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "maxUSDPrice", _vm._n($$v));
      },
      expression: "form.maxUSDPrice"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMaxUsd"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.maxUsd))])], 1)], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-minIDRPrice",
      "label": "Min IDR Order Price",
      "label-for": "minIDRPrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "minIDRPrice",
      "type": "number",
      "step": "any",
      "placeholder": "1.200.000",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'minIdr', 'idr');
      }
    },
    model: {
      value: _vm.form.minIDRPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minIDRPrice", _vm._n($$v));
      },
      expression: "form.minIDRPrice"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMinIdr"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.minIdr))])], 1)], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-maxIDRPrice",
      "label": "Max IDR Order Price",
      "label-for": "maxIDRPrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "maxIDRPrice",
      "type": "number",
      "step": "any",
      "placeholder": "1.200.000",
      "min": 0,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'maxIdr', 'idr');
      }
    },
    model: {
      value: _vm.form.maxIDRPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "maxIDRPrice", _vm._n($$v));
      },
      expression: "form.maxIDRPrice"
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMaxIdr"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.maxIdr))])], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-offerType",
      "label": "Offer Type",
      "label-for": "offerType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "offerType",
      "options": _vm.offerTypes,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.offerType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "offerType", $$v);
      },
      expression: "form.offerType"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_vm.form.offerType == 1 ? _c('b-form-group', {
    attrs: {
      "id": "input-selectedOffer",
      "label": "Selected Offer",
      "label-for": "selectedOffer"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea",
      "placeholder": "1,1,1,1,1",
      "rows": "8",
      "max-rows": "9",
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "change": function ($event) {
        _vm.form.publisher = [], _vm.form.generalOffer = null;
      }
    },
    model: {
      value: _vm.form.specifiedOffer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "specifiedOffer", $$v);
      },
      expression: "form.specifiedOffer"
    }
  })], 1) : _vm._e(), _vm.form.offerType == 2 ? _c('b-form-group', {
    attrs: {
      "id": "input-generalOffer",
      "label": "General Offer",
      "label-for": "generalOffer"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "generalOffer",
      "options": _vm.generalOffers,
      "required": "",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "change": function ($event) {
        _vm.form.specifiedOffer = '';
      }
    },
    model: {
      value: _vm.form.generalOffer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "generalOffer", $$v);
      },
      expression: "form.generalOffer"
    }
  })], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_vm.form.generalOffer == 10 || _vm.form.generalOffer == 11 || _vm.form.generalOffer == 12 || _vm.form.generalOffer == 13 ? _c('b-form-group', {
    attrs: {
      "label": "Publisher"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Publisher",
      "open-direction": "bottom",
      "options": _vm.publisherSearchOptions,
      "searchable": true,
      "loading": _vm.isPublisherSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    on: {
      "search-change": _vm.publisherFind
    },
    model: {
      value: _vm.form.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publisher", $$v);
      },
      expression: "form.publisher"
    }
  })], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1",
      "value": "true",
      "unchecked-value": "false",
      "disabled": _vm.formatingActive(_vm.form.min, _vm.form.valid_from_detail, _vm.form.valid_to_detail) == true
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }), _c('div', [_vm._v("Active: "), _c('strong', [_vm._v(_vm._s(_vm.form.is_active))])])], 1)])]), _c('div', {
    staticClass: "col-md-12 pb-3"
  }, [_c('b-button', {
    staticClass: "mr-3 text-white",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Update Discount ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/discounts');
      }
    }
  }, [_vm._v("Cancel")])], 1)])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }